import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from '@browniebroke/gatsby-image-gallery'
// import { Gallery } from "gatsby-theme-gallery";

class InterestsPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const images = data.images.edges.map(({ node }) => node.childImageSharp)
    // console.log("images", images)

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Arts" />
        {/* <article className="post-content page-template no-image">
        <div className="post-content-body"> */}
        <p align="center" margin="50"><iframe id="animation" width="854" height="480" src="https://www.youtube.com/embed/W2ykipvGbn0?start=61&end=132?rel=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe></p>
        <Gallery images={images}/>
        
        {/* <Gallery /> */}
        {/* </div>
        </article> */}
      </Layout>
    )
  }
}

export default InterestsPage

export const pageQuery = graphql`
query ImagesForGallery {
    site {
        siteMetadata {
          title
        }
    }
    images: allFile(
        filter: { sourceInstanceName: { eq: "gallery" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              thumb: gatsbyImageData(
                width: 500
                height: 500
                placeholder: BLURRED
              )
              full: gatsbyImageData(width: 1000)
            }
          }
        }
      }
  }
`